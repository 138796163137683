import React from 'react';

import { FormattedMessage } from 'gatsby-plugin-intl';

import DescWithImage from 'components/DescWithImage';

import './ProductsPage.scss';

const withLine = (messageId) => (
  <>
    <div className="line custom" />
    <div className="message">
      <FormattedMessage id={messageId} />
    </div>
  </>
);

const ProductsPage = () => (
  <div className="products-page">
    <div className="two-columns">
      <div className="left">
        <div className="line" />
        <h2>
          <FormattedMessage id="productsPagePart1Title" />
        </h2>
        <h3>
          <FormattedMessage id="productsPagePart1Content" />
        </h3>
      </div>
      <div className="right">
        <img src="/images/products-part1-bg.jpg" className="img-responsive" alt="" />
      </div>
    </div>
    <div className="part2">
      <DescWithImage
        title={<FormattedMessage id="productsPagePart2Title" />}
        contentPart1={<FormattedMessage id="productsPagePart2ContentPart1" />}
        contentPart2={<FormattedMessage id="productsPagePart2ContentPart2" />}
        imgSrc="/images/products-part2-bg.jpg"
      />
    </div>
    <div className="part3">
      <DescWithImage
        title={<FormattedMessage id="productsPagePart3Title" />}
        contentPart1={<FormattedMessage id="productsPagePart3ContentPart1" />}
        contentPart2={<FormattedMessage id="productsPagePart3ContentPart2" />}
        imgSrc="/images/products-part3-bg.jpg"
        column
      />
    </div>
    <div className="part4">
      <div className="line" />
      <h4>
        <FormattedMessage id="productsPagePart1Title" />
      </h4>
      <div className="wrapper">
        <div className="item">
          <img src="/images/products-part4-box1.jpg" className="img-responsive" alt="" />
          <div className="desc">
            <FormattedMessage id="productsPagePart4Box1" />
          </div>
        </div>
        <div className="item">
          <img src="/images/products-part4-box2.jpg" className="img-responsive" alt="" />
          <div className="desc">
            <FormattedMessage id="productsPagePart4Box2" />
          </div>
        </div>
      </div>
    </div>
    <div className="part5">
      <DescWithImage
        title={<FormattedMessage id="productsPagePart5Title" />}
        contentPart1={<FormattedMessage id="productsPagePart5ContentPart1" />}
        contentPart2={<FormattedMessage id="productsPagePart5ContentPart2" />}
        imgSrc="/images/products-part5-bg.jpg"
        column
      />
    </div>
    <div className="part6">
      <DescWithImage
        title={<FormattedMessage id="productsPagePart6Title" />}
        contentPart1={<FormattedMessage id="productsPagePart6ContentPart1" />}
        contentPart2={<FormattedMessage id="productsPagePart6ContentPart2" />}
        imgSrc="/images/products-part6-bg.jpg"
      />
    </div>
    <div className="part7">
      <DescWithImage
        title={<FormattedMessage id="productsPagePart7Title" />}
        contentPart1={<FormattedMessage id="productsPagePart7ContentPart1" />}
        contentPart2={<FormattedMessage id="productsPagePart7ContentPart2" />}
        contentPart3={<FormattedMessage id="productsPagePart7ContentPart3" />}
        imgSrc="/images/products-part7-bg.jpg"
        inverted
      />
    </div>
    <div className="part8">
      <DescWithImage
        title={<FormattedMessage id="productsPagePart8Title" />}
        contentPart1={<FormattedMessage id="productsPagePart8Content" />}
        contentPart2={withLine('productsPagePart8Point1')}
        contentPart3={withLine('productsPagePart8Point2')}
        contentPart4={withLine('productsPagePart8Point3')}
        contentPart5={withLine('productsPagePart8Point4')}
        imgSrc="/images/products-part8-bg.jpg"
      />
    </div>
  </div>
);

export default ProductsPage;
