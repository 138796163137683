import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './DescWithImage.scss';

const getLeftContent = (
  column, title, contentPart1, contentPart2, contentPart3,
  contentPart4, contentPart5,
) => (
  <>
    <div className={classnames('line', {
      center: column,
    })}
    />
    <h1>
      {title}
    </h1>
    <div className="desc">
      {contentPart1}
    </div>
    {
      contentPart2 && (
        <div className="desc top-margin">
          {contentPart2}
        </div>
      )
    }
    {
      contentPart3 && (
        <div className="desc top-margin">
          {contentPart3}
        </div>
      )
    }
    {
      contentPart4 && (
        <div className="desc top-margin">
          {contentPart4}
        </div>
      )
    }
    {
      contentPart5 && (
        <div className="desc top-margin">
          {contentPart5}
        </div>
      )
    }
  </>
);

const getRightContent = (imgSrc) => (
  <img src={imgSrc} className="img-responsive" alt="" />
);

const DescWithImage = ({
  imgSrc, title, contentPart1, contentPart2, contentPart3,
  contentPart4, contentPart5, column, inverted, isFront,
}) => (
  <div className={classnames('desc-with-image', {
    column,
    inverted,
    isFront,
  })}
  >
    <div className="two-cols">
      <div className="left">
        {
          inverted
            ? getRightContent(imgSrc)
            : getLeftContent(
              column, title, contentPart1, contentPart2,
              contentPart3, contentPart4, contentPart5,
            )
        }
      </div>
      <div className="right">
        {
          inverted
            ? getLeftContent(
              column, title, contentPart1, contentPart2,
              contentPart3, contentPart4, contentPart5,
            )
            : getRightContent(imgSrc)
        }
      </div>
    </div>
  </div>
);

DescWithImage.propTypes = {
  contentPart1: PropTypes.node.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  column: PropTypes.bool,
  contentPart2: PropTypes.node,
  contentPart3: PropTypes.node,
  contentPart4: PropTypes.node,
  contentPart5: PropTypes.node,
  inverted: PropTypes.bool,
  isFront: PropTypes.bool,
};

DescWithImage.defaultProps = {
  contentPart2: null,
  contentPart3: null,
  contentPart4: null,
  contentPart5: null,
  column: false,
  inverted: false,
  isFront: false,
};

export default DescWithImage;
